/* eslint-disable func-names,no-param-reassign */

const forcedQueryParams = [
	'studio',
	'studioUrl',
	'env',
	'zIndexInput',
	'mockRevenueData',
	'mockProductStatData',
];

const getStudioUrl = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const anyForcedParams = forcedQueryParams.some((param) =>
		queryParams.has(param)
	);
	if (anyForcedParams) {
		(function (history) {
			const { pushState, replaceState } = history;
			const modifyHistory = (func) => (state, unused, url) => {
				let modifiedUrl = url;
				if (url && typeof url === 'string') {
					const fullUrl = new URL(url, window.location.origin);
					const historyQueryParams = fullUrl.searchParams;
					forcedQueryParams.forEach((param) => {
						if (queryParams.has(param)) {
							historyQueryParams.set(param, queryParams.get(param));
						}
					});
					modifiedUrl = `${fullUrl.pathname}?${historyQueryParams.toString()}`;
				}
				return func.apply(history, [state, unused, modifiedUrl]);
			};
			history.pushState = modifyHistory(pushState);
			history.replaceState = modifyHistory(replaceState);
		})(window.history);
	}

	const studioParam = queryParams.get('studio');
	if (studioParam) {
		return `https://${studioParam}.amplifyapp.com`;
	}
	const studioUrl = queryParams.get('studioUrl');
	if (studioUrl) {
		return studioUrl;
	}
	if (process.env.REACT_APP_STUDIO_URL) {
		return process.env.REACT_APP_STUDIO_URL;
	}
	if (window.location.origin.includes('localhost:3000')) {
		return 'http://localhost:3001';
	}
	if (window.location.origin.includes('localhost:8081')) {
		return 'http://localhost:8082';
	}
	if (window.location.origin.includes('vercel.app')) {
		return 'https://storyly-studio.vercel.app';
	}
	if (window.location.origin.includes('amplifyapp.com')) {
		return 'https://master.d2tkgr6o0fn9qz.amplifyapp.com';
	}
	return window.location.origin.replace('dashboard', 'studio');
};
window.studioUrl = getStudioUrl();
// eslint-disable-next-line no-unused-expressions
import('./bootstrap');
